import React from "react";
import {isMobile} from "../libs/mobileHelp";
import {BALL_DEFAULT_VELOCITY, BAR_HEIGHT, BAR_WIDTH} from "../Constants";


class GameBoard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            intervalNumber: -1
        }


        this.velocity = props.velocity
        this.dx = 1
        this.dy = 1
        this.ballXPos = 10;
        this.ballYPos = 10;
        this.racketYPos = isMobile() ? 70 : 30
        this.racketPosition = 0
        this.canvasContext = null
        this.canvas = React.createRef()
    }

    componentDidMount() {

        window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
            window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

        if (isMobile()) {
            window.addEventListener("touchmove", this.handleMove.bind(this), false)
        } else {
            window.addEventListener('mousemove', this.handleMouseMove.bind(this));
        }

        this.canvasContext = this.canvas.current.getContext('2d');

    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseMove.bind(this));
        window.removeEventListener("touchmove", this.handleMove.bind(this), false)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.gameStarted === false && this.props.gameStarted) this.startGame()
        if (prevProps.velocity !== this.props.velocity) this.velocity = this.props.velocity
        if (prevProps.pause !== this.props.pause) this.pause()
    }

    startGame() {
        this.setState({
            intervalNumber: requestAnimationFrame(this.redrawGameBoard.bind(this))
        })
    }

    handleMove(e) {
        let touch = e.touches[0]
        if (touch.pageX < this.canvas.current.width) {
            this.racketPosition = Math.floor(touch.pageX)
        }
    }

    handleMouseMove(e) {
        if (e.clientX < this.canvas.current.width) {
            this.racketPosition = e.clientX
        }
    }

    pause() {
        if (this.props.pause) cancelAnimationFrame(this.state.intervalNumber)
        else this.setState({
            intervalNumber: requestAnimationFrame(this.redrawGameBoard.bind(this))
        })
    }

    cleanGameBoard() {
        this.canvasContext.beginPath();
        this.canvasContext.clearRect(0, 0, this.canvas.current.width, this.canvas.current.height);
        this.canvasContext.closePath();
    }

    redrawGameBoard() {

        this.cleanGameBoard()

        this.updateBarPosition(this.racketPosition)

        let touchedRacket = false
        if (this.ballXPos - 10 < 0 || (this.ballXPos + 10 > this.canvas.current.width )) {
            this.dx = -this.dx;

        } else if (this.doesBallTouchRacketOnTheLeftOrRightCorner()) {
            touchedRacket = true
            this.dx = -this.dy;
            this.dy = -this.dy;

        } else if (this.ballYPos - 10 < 0) {
            // trick when ball is actually inside the racket
            this.ballYPos = 10
            this.dy = -this.dy;

        } else if (this.doesBallTouchRacket()) {
            this.dy = -this.dy;
            touchedRacket = true
        }

        if (this.ballYPos + 10 >= this.canvas.current.height) this.gameOver()
        else {

            if (touchedRacket) {
                this.props.onPointHit()
                // trick when ball is actually inside the racket
                this.ballYPos = this.canvas.current.height - (this.racketYPos + 10)
            }

            this.ballXPos += this.dx * this.velocity
            this.ballYPos += this.dy * this.velocity

            this.drawBall()

            this.setState({
                intervalNumber: requestAnimationFrame(this.redrawGameBoard.bind(this))
            })
        }
    }

    allBallInsideTheRacket() {
        let result = false
        for (let i = this.ballXPos - 5;  i <= this.ballXPos + 5; i++) {
            result = (i >= this.racketPosition && i <= this.racketPosition + BAR_WIDTH)
            if (result) {
                break;
            }
        }
        return result
    }

    ballTouchLeftOrRightCorner() {
        let result = false
        for (let i = this.ballXPos - 5;  i <= this.ballXPos + 5; i++) {
            result = (i === this.racketPosition || i === this.racketPosition + BAR_WIDTH)
            if (result) {
                break;
            }
        }
        return result
    }

    doesBallTouchRacket() {
        return this.ballYPos + 10 > this.canvas.current.height - this.racketYPos
            && this.allBallInsideTheRacket()
    }

    doesBallTouchRacketOnTheLeftOrRightCorner() {
        return this.ballYPos + 10 > this.canvas.current.height - this.racketYPos
            && this.ballTouchLeftOrRightCorner()
    }

    drawBall() {
        this.canvasContext.save();
        this.canvasContext.fillStyle = '#db4437';
        this.canvasContext.beginPath();
        this.canvasContext.arc(this.ballXPos, this.ballYPos, 10, 0, Math.PI*2);
        this.canvasContext.fill();
        this.canvasContext.stroke();
        this.canvasContext.restore();
    }

    gameOver() {

        this.cleanGameBoard()

        this.ballXPos = 10;
        this.ballYPos = 10;
        this.velocity = BALL_DEFAULT_VELOCITY;
        this.dx = 1
        this.dy = 1
        this.props.onDie()
    }

    updateBarPosition(xPosition) {

        if (xPosition + BAR_WIDTH < this.canvas.current.width) {
            this.racketPosition = xPosition

        } else if (xPosition < 0) {
            this.racketPosition = 0

        } else if (xPosition + BAR_WIDTH > this.canvas.current.width) {
            this.racketPosition = this.canvas.current.width - BAR_WIDTH
        }

        this.canvasContext.save();
        this.canvasContext.strokeStyle = '#fff';
        this.canvasContext.fillStyle = '#000';
        this.canvasContext.beginPath();
        this.canvasContext.fillRect(this.racketPosition, this.canvas.current.height - this.racketYPos, BAR_WIDTH, BAR_HEIGHT);
        this.canvasContext.fill();
        this.canvasContext.stroke();
        this.canvasContext.restore();
    }

    render() {
        const {width, height} = this.props
        return <canvas ref={this.canvas} width={width} height={height} />
    }

}

export default GameBoard