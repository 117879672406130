import React from "react";
import {isMobile} from "../libs/mobileHelp";

const MenuPage = (props) => {

    return <div className="game-text">
                <div className="App-name">Easy Pong</div>
                <div><strong>Can you make it to 100 points ?</strong></div>
                {
                    isMobile()
                        ? (<div className="pop touch-zone"
                                onClick={props.handleStart.bind(this)}>Touch this to start</div>)
                        : (<div className="pop text-zone"><em>Press space to start and pause</em></div>)
                }
            </div>
}

export default MenuPage