import React from 'react';
import Helmet from 'react-helmet'
import {isMobile} from "./libs/mobileHelp";
import './App.css';
import GameBoard from "./components/GameBoard";
import MenuPage from "./components/MenuPage";
import ResultPage from "./components/ResultPage";


const scoreSteps = {
    1: {velocity: 3},
    2: {velocity: 3},
    3: {velocity: 4},
    5: {velocity: 4},
    8: {velocity: 5},
    13: {velocity: 5},
    21: {velocity: 6},
    34: {velocity: 7},
    55: {velocity: 8},
    89: {velocity: 9},
    100: {velocity: 10},
}

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            screen: {
                width: window.innerWidth,
                height: window.innerHeight
            },
            pause: false,
            gameOver: false,
            score: 0,
            velocity: 2,
            haveSeenMenu: false,
            gameStarted: false
        }
    }

    componentDidMount() {
        if (!isMobile()) {
            window.addEventListener('keyup', this.handleKeyBoard.bind(this));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyBoard.bind(this));
    }

    startGame(e) {
        if (!this.state.gameStarted) {
            this.setState({
                gameStarted: true,
                gameOver: false,
                pause: false,
                velocity: 2,
                score: 0
            })
        }
    }

    handleKeyBoard(e) {
        if (e.keyCode === 32){
            if (this.state.gameStarted) this.pause()
            else this.startGame()
        }
    }

    gameOver() {
        this.setState({
            gameOver: true,
            gameStarted: false
        })
    }

    pause() {
        this.setState({ pause: !this.state.pause })
    }

    addPoint() {
        let newScore = this.state.score + 1;
        let velocity = this.state.velocity;

        if (scoreSteps[newScore]) {
            velocity = scoreSteps[newScore].velocity
        }

        this.setState({
                    score: newScore,
                    velocity: velocity
                })
    }

    render() {

        return  <div className="App">
                <Helmet>
                    <meta property="og:url"           content="https://easypong.herokuapp.com" />
                    <meta property="og:type"          content="website" />
                    <meta property="og:title"         content="Can you make it to 100 points ?" />
                    <meta property="og:description"   content="Pong Pong Pong" />
                    <meta property="og:image"         content="https://www.easypong.fr/logo.png" />
                    <meta name="twitter:url"          content="https://easypong.herokuapp.com" />
                    <meta name="twitter:title"        content="Can you make it to 100 points ?" />
                    <meta name="twitter:description"  content={"Pong Pong Pong"}/>
                    <meta name="twitter:image"        content="https://www.easypong.fr/logo.png" />
                </Helmet>
                {
                    !this.state.gameStarted && !this.state.gameOver
                        ? <MenuPage handleStart={this.startGame.bind(this)} />
                        : null
                }
                {
                    this.state.gameOver && !this.state.gameStarted
                        ? <ResultPage score={this.state.score} handleStart={this.startGame.bind(this)} />
                        : null
                }
                {
                 this.state.gameStarted
                     ? <div className="score-text">{`Score: ${this.state.score}`}</div>
                     : null
                }

                <GameBoard width={this.state.screen.width}
                           height={this.state.screen.height}
                           gameStarted={this.state.gameStarted}
                           pause={this.state.pause}
                           velocity={this.state.velocity}
                           onDie={this.gameOver.bind(this)}
                           onPointHit={this.addPoint.bind(this)} />


                {
                    !this.state.gameStarted
                        ? <footer>Designed by <a href="https://www.soonagency.fr">Soon Agency</a></footer>
                        : null
                }
            </div>
    }
}

export default App;
